<template>
    <v-row justify="center" class="py-5" style="height: 100%; min-width: 240px;" align="center">
        <v-col cols="10" sm="8" md="6" lg="4">

            <!-- TODO: link to view account -->
            <!-- TODO: show featured products; allow organization to select which products are on front page, or set it up with special banners with a page builder, or something like that; and/or we can show links to view account, go to brand's homepage (link from their brandprofile), etc. we don't need to check if uesr is authenticated, because if they try to access a feature that is for authenticated users only they will get redirected to login page; -->
            <!-- TODO: the list of products needs to be styled better; if there's a product image then show the image and product name in a little card maybe -->
            <!-- TODO: show the cancel button differently, and make it clear that it empties the entire cart ... and not just goes back to last page... maybe there need to be separate buttons for these two things, properly labeled -->
            <template v-if="isViewReady && cart">
                <CheckoutProgress step="shipping" class="mb-6" :shipping="cart.require.require_shipping" :billing="cart.require.require_billing"/>
            </template>
            <v-card v-if="isViewReady && cart">
                <v-app-bar :color="primaryColor" dark flat dense>
                    <v-app-bar-title>Shipping information</v-app-bar-title>
                </v-app-bar>
                <v-card-text>
                    <!-- TODO: mention the actual number of items? (shippingItemList.length) we can have the list, and we should show it below the shipping address anyway, to show how much each item will cost to ship, and to group them into shipping boxes, etc. -->
                    <p>At least one item requires a shipping address. Where would you like it delivered?</p>

                    <PostalAddressForm v-model="shippingAddress"/>
                    <p class="text-caption red--text">&nbsp;
                        <span v-if="inputError.shipping_address">{{inputError.shipping_address}}</span>
                    </p>

                </v-card-text>
                <!-- TODO: probably show the shipiing and handling fee a different way ... -->
                <v-simple-table class="shipping-form">
                    <template #default>
                        <thead>
                            <tr>
                                <th class="py-2">Item</th>
                                <th class="py-2">Fee</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <tr v-for="(item, idx) in shippingItemList" :key="idx">
                                <th class="py-2">{{ item.name }}</th>
                                <td class="py-2">
                                    {{ item.subtotal }}
                                </td>
                            </tr> -->
                            <!-- TODO: compute and show the shipping and handling fee for the entire order -->
                            <tr>
                                <th class="py-2">Shipping &amp; handling</th>
                                <td class="py-2">
                                    {{ formatAmount('USD', 9.95) }} <!-- TODO: compute or load the shipping and handling fees -->
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <!-- TODO: subtotal, shipping/taxes, total -->
                <v-card-actions>
                    <v-spacer/>
                    <v-btn :style="primaryButtonStyle" @click="submit">Continue</v-btn>
                </v-card-actions>
            </v-card>
            <!-- TODO:  if there's an error loading the cart, show temporary error: -->
            <v-card elevation="2" v-if="isViewReady && !cart">
                <v-app-bar color="red darken-2" dark flat dense>
                    <v-app-bar-title>Maintenance</v-app-bar-title>
                </v-app-bar>
                <v-card-text class="pt-8">
                    <p>The server is temporarily unavailable.</p>
                    <!-- TODO:  We are going to automatically retry until it's ready. Please wait... -->
                    <!-- <p>Return to the last page and contact the emergency home for details.
                    <v-btn :to="loginRoute" elevation="2" :style="primaryButtonStyle" class="my-6" outlined>Sign in</v-btn>
                    <p>No account yet? <a :href="mainWebsiteURL">Sign up</a></p> -->
                    <!-- <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Unicorn Springs</a></p> -->
                </v-card-text>
            </v-card>
            <!-- <template v-if="!isAuthenticatedReady">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="isAuthenticatedReady">
                <template v-if="isAuthenticated">
                    <v-row style="height: 100%" align="center" justify="center">
                        <div class="app-splash-loader"></div>
                        <p class="mt-6">
                        <router-link :to="{ name: 'dashboard' }">Continue to dashboard</router-link>
                        </p>
                    </v-row>
                </template>
                <template v-if="!isAuthenticated">
                    <LoginCard/>
                </template>
            </template> -->
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
.v-input .v-input__prepend-outer {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
/* remove messages area from inputs */
.v-input.v-input--checkbox.hide-messages .v-messages {
    display: none;
}
/* align table headings and content to top */
.shipping-form th {
    vertical-align: top;
}
.shipping-form td {
    vertical-align: top;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { toMillis } from '@libertyio/time-util-js';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
// import { loadStripe } from '@stripe/stripe-js';
import CheckoutProgress from '@/components/CheckoutProgress.vue';
import PostalAddressForm from '@/components/PostalAddressForm.vue';

function isValidPostalAddress(address) {
    let error = false;
    ['name', 'line1', 'city', 'state', 'code', 'country'].forEach((attr) => {
        if (!address[attr]) {
            error = true;
        }
    });
    // TODO: validate country value (should be an ISO two or three letter code)
    // TODO: validate state name or abbrev (should be a standard name or abbrev , depends on country)
    // TODO: validate postal code (depends on coutnry)
    return !error;
}

export default {
    components: {
        CheckoutProgress,
        PostalAddressForm,
    },
    data: () => ({
        status: null, // 'pending' or 'finalized'
        customerId: null,
        orderId: null,
        paymentId: null,
        input: {
            requiring_shipping: true,
        },
        customer: {
            type: null,
        },
        shippingAddress: {
            name: null,
            line1: null,
            line2: null,
            line3: null,
            line4: null,
            line5: null,
            city: null,
            state: null,
            code: null,
            country: null,
        },
        addressList: [],
        isViewReady: false,
        submitTimestamp: null,
        inputError: {
            shipping_address: null,
        },
        inputErrorTimeout: {
            shipping_address: null,
        },
        // mode: 'prompt', // 'prompt' shows line items and continue button, 'email' prompts for email
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            brandNotFoundError: (state) => state.brandNotFoundError,
            focus: (state) => state.focus,
            cart: (state) => state.cart,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        frontLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-front', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'front' };
            }
            return link;
        },
        searchLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'search', params: { brandprofile: this.brandprofile } }; // TODO: should the name be 'brand-search' ? we don't have a route for that defined right now in router.js
            } else {
                link = { name: 'search' };
            }
            return link;
        },
        checkoutAccountLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-account', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-account' };
            }
            return link;
        },
        receiptLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-receipt', params: { brandprofile: this.brandprofile }, query: { payment_intent: this.$route.query.payment_intent } };
            } else {
                link = { name: 'main-receipt', query: { payment_intent: this.$route.query.payment_intent } };
            }
            return link;
        },
        billingLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-shipping', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-shipping' };
            }
            return link;
        },
        finalizeLink() {
            let link;
            if (this.brandselector === 'brandprofile') {
                link = { name: 'brand-checkout-finalize', params: { brandprofile: this.brandprofile } };
            } else {
                link = { name: 'main-checkout-finalize' };
            }
            return link;
        },
        shippingItemList() {
            if (!Array.isArray(this.cart?.items)) {
                return [];
            }
            return this.cart.items.filter((item) => item.require_shipping);
        },
    },
    watch: {
        isAuthenticatedReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.init();
            }
        },
        // isAuthenticated(newValue, oldValue) {
        //     if (newValue && !oldValue) {
        //         this.redirectAuthenticatedUser();
        //     }
        // },
        brandprofile(newValue, oldValue) {
            if (newValue && newValue !== oldValue) {
                this.init();
            }
        },
        focus() {
            if (this.brandprofile) {
                this.init();
            }
        },
        name(newValue, oldValue) {
            if (this.billingAddress?.name === null || this.billingAddress.name.length === 0 || this.billingAddress.name === oldValue) {
                this.$set(this.billingAddress, 'name', newValue);
            }
            if (this.shippingAddress?.name === null || this.shippingAddress.name.length === 0 || this.shippingAddress.name === oldValue) {
                this.$set(this.shippingAddress, 'name', newValue);
            }
        },
    },
    methods: {
        // redirectAuthenticatedUser() {
        //     // TODO: we need to check query parameters, if there's an organization id redirect to the dashboard for that organization, otherwise if there's only one organization, go to that one, or if there's more than one show the organization selection , and if the user doens't have any organizations then show a message that they need to contact the administrator to be added to an organization
        //     this.$router.replace({ name: 'dashboard' });
        // },
        async finalize() {
            try {
                this.error = false;
                // 'payment_intent', 'payment_intent_client_secret', and 'redirect_status=succeeded'
                this.$store.commit('loading', { finalize: true });
                const payment = {
                    payment_intent: this.$route.query.payment_intent,
                    name: this.name,
                    email: this.email,
                    signup: this.signup,
                    billing_address: this.billingAddress,
                    shipping_address: this.shippingAddress,
                    // payment_intent_client_secret: this.$route.query.payment_intent_client_secret,
                    // redirect_status: this.$route.query.redirect_status,
                };
                const response = await this.$client.site(this.brandprofile).cart.finalize(payment);
                if (response?.status === 'finalized') {
                    this.status = response.status;
                } else if (response?.status === 'pending') {
                    /*
{
    "customerId": "06J5JAM8AFRQDH2Q5XG0",
    "orderId": "06J5JB3M9H9HSWXH92QG",
    "paymentId": "06J5JCK6MRCHBVH2XDDG",
    "input": {
        "require_name": false,
        "require_email": false,
        "require_signup": false,
        "require_shipping": false,
        "require_billing": false
    },
    "customer": {
        "type": "guest"
    },
    "order": {
        "name": null,
        "email": null,
        "billing_address": null,
        "shipping_address": null
    }
}
                    */
                    this.status = response.status;
                    this.customerId = response.customerId;
                    this.orderId = response.orderId;
                    this.paymentId = response.paymentId;
                    this.input = response.input; // { require_name, require_email, require_signup, require_shipping, require_billing }
                    this.customer = response.customer; // { type }
                    this.name = response.order?.name;
                    this.email = response.order?.email;
                    this.billingAddress = response.order?.billing_address;
                    this.shippingAddress = response.order?.shipping_address;
                } else {
                    this.error = true;
                }
            } catch (err) {
                console.error('finalize failed', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { finalize: false });
                this.isViewReady = true;
            }
        },
        async submit() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            let error = false;
            this.submitTimestamp = Date.now();
            if (this.input.require_shipping && !isValidPostalAddress(this.shippingAddress)) {
                this.inputError.shipping_address = 'Please enter a shipping address';
                if (this.inputErrorTimeout.shipping_address) {
                    clearTimeout(this.inputErrorTimeout.shipping_address);
                }
                this.inputErrorTimeout.shipping_address = setTimeout(() => { this.$set(this.inputError, 'shipping_address', null); }, toMillis({ seconds: 10 }));
                error = true;
            }
            if (!error) {
                // TODO: where to go next? need to submit the shupiping address to server ... but there's no payment or oder yet, so it will be stored in the CART ...
                // await this.finalize();
                // if (this.status === 'finalized') {
                //     // order is finalized, so redirect user to view the receipt
                //     this.$nav.push(this.receiptLink);
                // }
            }
        },
        formatAmount(currency, amount) {
            const price = fromCurrencyAmountCSU(currency, amount ?? 0).toNumber();
            const display = new Intl.NumberFormat('en-US', { // TODO: localization, if we know user's locale use that instead of en-US
                currency,
                style: 'currency',
            }).format(price);
            return display;
        },
        async next() {
            if (this.cart?.status === 'ready') {
                // because we're on the shipping step and not the order view step, go to order review, in case customer needs to change anything else before they pay
                this.$nav.push(this.finalizeLink);
            }
            if (this.cart?.status === 'finalize' && Array.isArray(this.cart?.finalize) && this.cart.finalize.includes('shipping_address')) {
                return; // stay here and continue to prompt for required shipping address
            }
            if (this.cart?.status === 'finalize' && Array.isArray(this.cart?.finalize) && this.cart.finalize.includes('billing_address')) {
                this.$nav.push(this.billingLink);
            }
            // anything else, stay here
        },
        async init() {
            await this.$store.dispatch('loadCart');
            this.isViewReady = true;
        },
    },
    mounted() {
        // if (this.hostname === window.location.hostname) {
        //     // special case for front page of main site, it's not a storefront
        // }
        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
        if (this.brandprofile) {
            this.init();
            // this.finalize();
        }
    },
};
</script>
